body {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  font-family: Avenir;
}
.root {
  height: 100%;
  width: 100%;
}
.main {
  width: 100%;
  height: 100%;
  margin: auto;
}

h1, h2, h3, p, a {
  margin: 0;
  color: #001f3f;
}
.description{
  color: #001f3f;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: medium;
}
h2, h3 {
  margin-bottom: 20px;
}
p, a {
  font-weight: bold;
}
a {
  color: #001f3f;
  height: 20px;
  width: 80%;
}
input.textarea {
  color: #001f3f;
  height: 20px;
  width: 80%;
  margin: auto;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  padding: 0.6rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.55rem;
  background-color: #ffffff;
  border: 0.0625rem solid #d1d9e6;
  border-radius: 0.55rem;
  box-shadow: inset 2px 2px 5px #bdbdbd,
              inset -3px -3px 7px #ffffff;
  transition: all .3s ease-in-out;
  margin-top: 10px;
  margin-bottom: 10px;
}
input.textarea:focus {
  outline: none;
}
.button {
  height: 30px;
  padding: 1px 10px;
  margin: auto;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
  border-radius: 0.55rem;
  background-color: #ffffff;
  border: 0.0625rem solid #d1d9e6;
  border-radius: 0.55rem;
  transition: all .3s ease-in-out;
  color: #001f3f;
}
.button:active {
  box-shadow: inset 2px 2px 10px #bdbdbd,
              inset -3px -3px 10px #ffffff;
  transition: all .1s ease-in-out;
}
.disabled-button {
  color: #d1d9e6;
  pointer-events: none;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #ffffff;
  box-shadow: 5px 5px 10px #bdbdbd,
              -5px -5px 10px #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
}
.header > h1 {
  width: 100%;
}
.header-icons {
  position: absolute;
  right: 0px;
}
.header-icon {
  margin-right: 1.5rem;
  cursor: pointer;
}

.footer {
  width: 100%;
}

.contents {
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 100px;
}
/* .contents >div {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: stretch;
  justify-content: center;
} */

.url-input-card {
  width: 60%;
  margin: auto;
  padding: 40px 50px 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 33px;
  background: #ffffff;
  box-shadow: 5px 5px 10px #bdbdbd,
              -5px -5px 10px #ffffff;
  margin-bottom: 50px;
}
.created-url {
  margin: auto;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  background: #ffffff;
  border: #44476a;
  box-shadow: inset 1px 1px 3px #b8b9be,
              inset -1px -1px 3px #fff;
}
.button {
  cursor: pointer;
}
.copied-popup {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 20%;
  margin: auto;
  margin-bottom: 10px;
  background-color: #00bcd4;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
}
.copied-text {
  margin-left: 5px;
  font-size: 0.8rem;
  color: #ffffff;
}

.past-url-card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 33px;
  background: #ffffff;
  box-shadow: 5px 5px 10px #bdbdbd,
              -5px -5px 10px #ffffff;
  margin-bottom: 50px;
}
.past-url-card-date {
  margin-bottom: 10px;
  font-size: small;
}
.past-url-card-title {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.past-url-card-title > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.past-url-card-title-text {
  margin-left: 10px;
  margin-bottom: 0px;
  display: inline-block;
}
.past-url-card-table {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 10px;
  width: 60%;
}
.table-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: solid 0.5px #44476a;
}
.table-row > p, a {
  padding: 5px 0px;
}
.table-key {
  width: 35%;
}
.table-value {
  text-align: left;
  width: 65%;
  white-space: nowrap;
  overflow-X: scroll;
  -ms-overflow-style: none;
  margin: auto;
  border-radius: 5px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-right: 10px;
  background: #ffffff;
  border: #44476a;
  box-shadow: inset 1px 1px 3px #b8b9be,
              inset -1px -1px 3px #fff;
}
.table-value::-webkit-scrollbar {
  display: none;
}

.url-valid-popup {
  margin: auto;
  background-color: #FFBABA;
  color: #D8000C;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
}
.url-valid-text {
  margin-left: 5px;
  font-size: 0.8rem;
  color: #D8000C;
}

.table-value-user-card {
  height: 20px;
  text-align: left;
  width: 65%;
  white-space: nowrap;
  overflow-X: scroll;
  -ms-overflow-style: none;
  margin: auto;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  background: #ffffff;
  border: #44476a;
  font-weight: bold;
  box-shadow: inset 1px 1px 3px #b8b9be,
              inset -1px -1px 3px #fff;
}
.table-value-user-card::-webkit-scrollbar {
  display: none;
}
.table-value-user-card:focus {
  outline: none;
}

.past-url-card-table-submited {
  margin-bottom: 20px;
}

.table-row-profile {
  width: 70%;
  margin: auto;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: solid 0.5px #44476a;
  display: flex;
  flex-direction: column;
}
.table-row-profile > p, input {
  padding: 5px 0px;
}
.table-row-profile-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.profile-valid-popup {
  margin: auto;
  margin-top: 10px;
  background-color: #FFBABA;
  color: #D8000C;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: all .3s ease-in-out;
}
.profile-valid-text {
  margin-left: 5px;
  font-size: 0.8rem;
  color: #D8000C;
}

.table-card {
  width: 90%;
  max-height: 500px;
  overflow-y: scroll;
  margin: auto;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #bdbdbd,
              -5px -5px 10px #ffffff;
}
table th, table td {
  border-bottom: solid 0.5px #001f3f;
}
table {
  border-collapse: collapse;
  width: 100%;
  margin: auto;
  padding: 30px 20px;
}
thead tr {
  top: 0;
  position: sticky;
  position: -webkit-sticky;
  background: #001f3f;
}
thead th {
  color: #ffffff;
}
th {
  text-align: center;
  padding: 8px;
  color: #001f3f;
  cursor: pointer;
}
td {
  padding: 15px;
  overflow-X: scroll;
  color: #001f3f;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 0;
}
.table-value-td {
  white-space: nowrap;
  overflow-X: scroll;
  border-radius: 5px;
  padding: 5px 10px !important;
  background: #ffffff;
  border: #44476a;
  box-shadow: inset 1px 1px 2px #b8b9be,
              inset -1px -1px 2px #fff;
}
.table-value-td::-webkit-scrollbar {
  display: none;
}
.sort-icon {
  margin-left: 5px;
}
.table-cell {
  white-space: normal !important;
  max-width: 60px !important;
  text-overflow: unset !important;
}

.login-input-card {
  width: 40%;
  margin: auto;
  padding: 20px 50px 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 33px;
  background: #ffffff;
  box-shadow: 5px 5px 10px #bdbdbd,
              -5px -5px 10px #ffffff;
  margin-bottom: 50px;
}
.shared-url-card {
  width: 60%;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 33px;
  background: #ffffff;
  box-shadow: 5px 5px 10px #bdbdbd,
              -5px -5px 10px #ffffff;
  margin-bottom: 30px;
}
.signin-type-seletct {
  margin: auto;
  width: 80%;
}
.signin-type-seletct p {
  text-align: right;
  font-size: 13px;
  color: #7b95ae;
  cursor: pointer;
}
.password-requirements {
  margin: auto;
  margin-bottom: 10px;
  width: 80%;
  display: flex;
  justify-content: center;
}
.password-requirements p {
  text-align: left;
  font-size: 10px;
  color: #7b95ae;

}

.warning-message {
  margin: auto;
  margin-bottom: 10px;
  width: 80%;
}
.warning-message p {
  text-align: center;
  font-size: 15px;
  color: #F13C38;
}

.input-signature {
  height: 20px;
  text-align: center;
  width: 65%;
  white-space: nowrap;
  overflow-X: scroll;
  -ms-overflow-style: none;
  margin: auto;
  border-radius: 5px;
  padding: 5px 10px;
  background: #ffffff;
  border: #44476a;
  font-weight: bold;
  box-shadow: inset 1px 1px 3px #b8b9be,
              inset -1px -1px 3px #fff;
}
.input-signature::-webkit-scrollbar {
  display: none;
}
.input-signature:focus {
  outline: none;
}

.url-table-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.url-table-title > h2 {
  margin: 20px 30px;
  /* cursor: pointer; */
}

.login-link {
  margin: auto;
  margin-right: 30px;
  text-decoration: none;
}